import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OrderModel } from 'src/app/core/domain/order-model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { ORDER_STATUSES, PREPAID_ORDERS } from 'src/app/presentation/shared/constants';
import { Country } from 'src/app/presentation/shared/interfaces/countries';
import { ShippingService } from 'src/app/presentation/shared/services/shipping.service';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import {
  WEB_CUSTOM_CHECKOUT,
  WEB_ORDERS_REVAMP,
  WEB_TELE_SALES_ORDERS,
} from '../../shared/constants/feature-flags';
import { TAAGER_CANCELLED_STATUS } from '../../shared/constants/order-statuses';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { CancelOrderDialogComponent } from '../cancel-order-dialog/cancel-order-dialog.component';
import { ChildOrderItemDialogComponent } from '../child-order-item-dialog/child-order-item-dialog.component';
import { OrderCompletionComponent } from '../order-completion/order-completion.component';
import { OrderItemDialogComponent } from '../order-item-dialog/order-item-dialog.component';
import { OrderRefundsComponent } from '../order-refunds/order-refunds.component';
import { OrderReplacementsComponent } from '../order-replacements/order-replacements.component';
import { OrderTrackingDialogComponent } from '../order-tracking-dialog/track-orders-dialog.component';
import { RatingBarComponent } from '../rating-bar/rating-bar.component';
import { RefundsPolicyDialogComponent } from '../refunds-policy-dialog/refunds-policy-dialog.component';
import { TrackOrdersDialogComponent } from '../track-orders-dialog/track-orders-dialog.component';

declare global {
  interface Window {
    order: any;
    sendMessageAboutAnOrder(): any;
  }
}
@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, TranslateModule, NgTemplateOutlet],
})
export class OrderOverviewComponent implements OnInit {
  @Input() order: OrderModel;

  @Input() draft = false;

  @Output() cancelOrderDialogClosed: EventEmitter<void> = new EventEmitter();

  public currentRate = 0;

  public statusStage = '';

  public orderIssueMessage = '';

  public showAdditon = false;

  public childOrders: any;

  public shipmentCompaniesList: any;

  public shippmentTrackingExists = false;

  public orderCountry: Country;

  public isDraftOrder = false;

  public isEnglishLanguage = false;

  private _languageChangeSubscription: Subscription;

  public hasPrepaidFeature = false;

  public additionalNote: string;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public assetsPath = 'assets/img/on-boarding/';

  public shouldShowTeleSales = false;

  public shouldEnableOrdersRevamp = false;

  public shouldEnableProductCheckout = false;

  constructor(
    private dialog: MatDialog,
    private shippingService: ShippingService,
    private clipboard: Clipboard,
    private toastr: ToastrService,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _renderer: Renderer2,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _clipboard: Clipboard,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
    this._checkShouldShowTeleSales();
  }

  ngOnInit(): void {
    if (this.order.rating) {
      this.currentRate = this.order.rating;
    }
    this.returnOrderStatus(this.order.status);

    this.shipmentCompaniesList = {
      bosta: this.reloadBostaShipmentStatus,
      dreevo: this.reloadDreevoShipmentStatus,
      aramex: this.reloadAramexShipmentStatus,
      vhubs: this.reloadVHubsShipmentStatus,
    };
    if (this.shipmentCompaniesList.hasOwnProperty(this.order.shippingInfo?.company)) {
      this.shippmentTrackingExists = true;
    }

    if (this.order.errorMessage) {
      this.order.errorMessage =
        ORDER_STATUSES.ORDER_ERRORS.filter((error) =>
          this.order?.errorMessage?.includes(error.description),
        )[0]?.translationKey || 'ERRORS.GENERIC_ERROR_MESSAGE';
    }

    this.showOrderAdditon();
    this._checkProductCheckoutEnabled();

    this.orderCountry = getCountryFromIsoCode3(this.order.country);
    const orderKey = this.order?.orderID || this.order?.orderId;
    this.isDraftOrder = !orderKey?.toString().includes('/');
    this._checkDuplicateOrderAndUpdateNote();

    this.getOrdersRevampStatus();
    this.hasPrepaidFeature = this._checkUserFeatureExistsUseCase.execute(PREPAID_ORDERS);
    if (this.order?.orderId) {
      this.order.orderID = this.order.orderId;
    }
  }

  private _checkProductCheckoutEnabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_CUSTOM_CHECKOUT).subscribe({
      next: (attribute) => {
        this.shouldEnableProductCheckout = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
  }

  openChatModel(orderObj: any): void {
    /* eslint-disable */
    this._logMixpanelEventUseCase.execute({
      eventName: 'Open_order_message',
      payload: {
        'Order Id': orderObj.orderID,
        Status: orderObj.status,
        'Shipping Company':
          orderObj.shippingInfo && orderObj.shippingInfo.company
            ? orderObj.shippingInfo.company
            : 'No company',
      },
    });
    this.clipboard.copy(orderObj.orderID);
    /* eslint-enable */
    this.toastr.info(this._translateService.instant('ORDERS_PAGE.ORDER_ID_COPIED'));
    this.openIntegratedChat();
  }

  openIntegratedChat(): void {
    const script = this._renderer.createElement('script');
    script.type = `text/javascript`;
    script.text = `window.fcWidget.open()`;
    this._renderer.appendChild(this.document.body, script);
  }

  openOrderRefunds(orderObj: any): void {
    const dialogRefOrderChat = this.dialog.open(OrderRefundsComponent, {
      width: '650px',
      data: {
        order: orderObj,
      },
      disableClose: true,
    });
    dialogRefOrderChat.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (result.data === 'confirmed') {
            this.order.hasIssue = true;
          }
        }
      },
    });
  }

  openOrderReplacements(orderObj: any): void {
    const dialogRefOrderChat = this.dialog.open(OrderReplacementsComponent, {
      width: '650px',
      data: {
        order: orderObj,
      },
      disableClose: true,
    });
    dialogRefOrderChat.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (result.data === 'confirmed') {
            this.order.hasIssue = true;
          }
        }
      },
    });
  }

  openOrderCompletion(orderObj: any): void {
    const dialogRefOrderChat = this.dialog.open(OrderCompletionComponent, {
      width: '650px',
      data: {
        order: orderObj,
      },
      disableClose: true,
    });
    dialogRefOrderChat.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          if (result.data === 'confirmed') {
            this.order.hasIssue = true;
          }
        }
      },
    });
  }

  public getOrdersRevampStatus(): void {
    this._getFeatureAttributeUseCase.execute(WEB_ORDERS_REVAMP).subscribe({
      next: (status) => {
        this.shouldEnableOrdersRevamp = featureAttributeAssign(status, user.id, country.code);
      },
    });
  }

  onTrackOrderActivity(order: any): void {
    /* eslint-disable */
    this._logMixpanelEventUseCase.execute({
      eventName: 'Track_order',
      payload: {
        'Order Id': order.orderID,
        Status: order.status,
        'Shipping Company':
          order.shippingInfo && order.shippingInfo.company
            ? order.shippingInfo.company
            : 'No company',
      },
    });
    /* eslint-enable */
    const dialogObject = {
      width: this.shouldEnableOrdersRevamp ? '1030px' : '1200px',
      data: {
        order,
        viewProducts: false,
      },
      panelClass: 'custom-modalbox',
    };
    let dialogRef;
    if (this.shouldEnableOrdersRevamp) {
      dialogRef = this.dialog.open(OrderTrackingDialogComponent, dialogObject);
    } else {
      dialogRef = this.dialog.open(TrackOrdersDialogComponent, dialogObject);
    }
  }

  returnOrderStatus(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    )[0];

    this.statusStage = statusObject ? statusObject.statusStage : 'received';

    return statusObject ? statusObject.statusTranslationKey : recievedStatus;
  }

  matchDeliverySuspendedReason(reason: string | undefined): string {
    return ORDER_STATUSES.DELIVERY_SUSPENSION_REASONS_TRANSLATION[reason!] || 'لا يوجد حالياً';
  }

  checkChildOrders(status: string): boolean {
    return ORDER_STATUSES.CHILD_ORDERS_STATUSES_ARRAY.includes(status);
  }

  convertDate(mongo: any): string {
    if (mongo) {
      const date = new Date(mongo);
      return date.toLocaleDateString('en-US');
    }
    return '';
  }

  rateOrder(): void {
    const dialogRef = this.dialog.open(RatingBarComponent, {
      width: '750px',
      data: {
        currentRate: this.currentRate,
        order: this.order,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          this.currentRate = result.rating;
        }
        /*eslint-disable*/
        this._logMixpanelEventUseCase.execute({
          eventName: 'Order_rating',
          payload: {
            'Order Id': this.order.orderID,
            Status: this.order.status,
            Rating: this.currentRate,
            'Shipping Company':
              this.order.shippingInfo && this.order.shippingInfo.company
                ? this.order.shippingInfo.company
                : 'No company',
          },
        });
        /* eslint-enable */
      },
    });
  }

  changeOrderStatus(order: any): void {
    const dialogRef = this.dialog.open(CancelOrderDialogComponent, {
      width: '550px',
      data: {
        order,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res?.status) {
          this.cancelOrderDialogClosed.emit();
        }
      },
    });
  }

  onItemsDetails(order: any): void {
    /*eslint-disable*/
    this._logMixpanelEventUseCase.execute({
      eventName: 'Order_details',
      payload: {
        'Order Id': order.orderID,
        Status: order.status,
        'Shipping Company':
          order.shippingInfo && order.shippingInfo.company
            ? order.shippingInfo.company
            : 'No company',
      },
    });
    /* eslint-enable */
    const dialogRef = this.dialog.open(OrderItemDialogComponent, {
      width: '950px',
      data: {
        order,
        viewProducts: true,
        draft: this.draft,
      },
    });
  }

  onChildOrdersDetails(): void {
    const dialogRef = this.dialog.open(ChildOrderItemDialogComponent, {
      width: '800px',
      data: {
        childOrders: this.childOrders,
        order: this.order,
        statusStage: this.statusStage,
        displayIDsInTable: true,
      },
    });
  }

  openRefundPolicy(): void {
    const dialogRef = this.dialog.open(RefundsPolicyDialogComponent, {
      width: '800px',
    });
  }

  showOrderAdditon(): void {
    if (
      !this.order.isOrderVerified &&
      (this.order.orderLines.length > 1 || this.order.orderLines[0].quantity > 1)
    ) {
      this.showAdditon = true;
    }
  }

  reloadShipmentStatus(): void {
    if (this.shipmentCompaniesList[this.order.shippingInfo.company]) {
      this.shipmentCompaniesList[this.order.shippingInfo.company].call(this);
    }
  }

  reloadAramexShipmentStatus(): void {
    if (this.order.shippingInfo.company === 'aramex') {
      const formData = {
        trackingNumber: this.order.shippingInfo.trackingNumber,
      };
      this.shippingService.trackShipmentFromAramex(formData).subscribe({
        next: (res) => {
          this.onTrackAramexOrder(res.trackingResults);
        },
      });
    }
  }

  reloadVHubsShipmentStatus(): void {
    if (this.order.shippingInfo.company === 'vhubs') {
      const formData = {
        trackingNumber: this.order.shippingInfo.trackingNumber,
        orderID: this.order.orderID,
      };
      this.shippingService.trackShipmentFromVHubs(formData).subscribe({
        next: (res) => {
          this.onTrackVHubsOrder(res.data);
        },
      });
    }
  }

  reloadBostaShipmentStatus(): void {
    if (this.order.shippingInfo.company === 'bosta') {
      const formData = {
        trackingNumber: this.order.shippingInfo.trackingNumber,
      };
      this.shippingService.trackPackageFromBosta(formData).subscribe({
        next: (res) => {
          this.onTrackBostaOrder(res);
        },
      });
    }
  }

  reloadDreevoShipmentStatus(): void {
    const formData = {
      WaybillNumbers: this.order.shippingInfo.trackingNumber, //eslint-disable-line
    };
    this.shippingService.trackPackageFromDreevo(formData).subscribe({
      next: (res) => {
        this.onTrackDreevoOrder(res.data.waybillTrackDetailList[0].waybillTrackingDetail);
      },
    });
  }

  onTrackDreevoOrder(waybillTrackingDetail: any): void {
    const dialogRef = this.dialog.open(OrderItemDialogComponent, {
      width: '800px',
      data: {
        order: this.order,
        waybillTrackingDetail,
        viewProducts: false,
      },
    });
  }

  onTrackBostaOrder(res: any): void {
    const dialogRef = this.dialog.open(OrderItemDialogComponent, {
      width: '800px',
      data: {
        order: this.order,
        TransitEvents: res.data.TransitEvents, //eslint-disable-line
        viewProducts: false,
      },
    });
  }

  onTrackAramexOrder(aramexTrackingResults: any): void {
    const dialogRef = this.dialog.open(OrderItemDialogComponent, {
      width: '800px',
      data: {
        order: this.order,
        aramexTrackingResults,
        viewProducts: false,
      },
    });
  }

  onTrackVHubsOrder(vhubsTrackingResults: any): void {
    const dialogRef = this.dialog.open(OrderItemDialogComponent, {
      width: '800px',
      data: {
        order: this.order,
        vhubsTrackingResults,
        viewProducts: false,
      },
    });
  }

  public copyTrackingLink(trackingLink: string): void {
    this._clipboard.copy(trackingLink);
    this.toastr.info('Tracking Link Copied');
  }

  public openTrackingLink(trackingLink: string): void {
    window.open(trackingLink, '_blank');
  }

  private _checkDuplicateOrderAndUpdateNote(): void {
    if (
      this.order.status === TAAGER_CANCELLED_STATUS &&
      this.order.cancellationReason
        ?.toUpperCase()
        .includes('potentially duplicate of'.toUpperCase())
    ) {
      const duplicateOrdersIds = this.order.cancellationReason.match(/\d+\/\d+/g);
      this.additionalNote = `يوجد تشابه مع ${duplicateOrdersIds!.join(' - ')}`;
    }
  }

  private _checkShouldShowTeleSales(): void {
    this._getFeatureAttributeUseCase.execute(WEB_TELE_SALES_ORDERS).subscribe((attribute) => {
      this.shouldShowTeleSales = featureAttributeAssign(attribute, user.id);
    });
  }
}
